import { request } from '@/utils/request-utils.js';


// ================================================================================================
// 平台 - 拼多多
// ================================================================================================

// 获取授权验证地址
export function apiPlatformPddGetOauthUrl(data) {
    return request({url: `/api/merchant/platform/pdd/getOauthUrl`, method: 'get', params: data, token: true})
}
