import { request } from '@/utils/request-utils.js';




// ================================================================================================
// 商户
// ================================================================================================
// 根据商户id获取店铺pair
export function apiV1GetShopPairs(data) {
    return request({url: `/api/merchant/v1/merchant/shops/shops-pairs`, method: 'get', params: data, token: true})
}


// ================================================================================================
// 商户 - 伙伴信息
// ================================================================================================

// 获取 伙伴 所属商户店铺 级联数据
// export function apiGetCurrentPartnerMerchantshopPermissionCascaderData(data) {
//     return request({url: `/api/merchant/partner/partners/current/merchantshop-permission-cascader-data`, method: 'get', params: data, token: true})
// }

// 主页内容
export function apiGetCurrentPartnerDashboardProfile(data) {
    return request({url: `/api/merchant/partner/partners/current/dashboard-profile`, method: 'get', params: data, token: true})
}



// 根据商户id获取店铺pair
export function apiGetShopPairsByMerchantId(data) {
    return request({
        url: `/api/admin/merchant/merchants/${data.merchant_id}/shops-pairs`,
        method: 'get',
        // params: data,
        token: true,
    })
}


// 查询键值对 商户仓库
export function apiV1GetStorePairs(data) {
    return request({url: `/api/admin/v1/merchant/store-pairs`, method: 'get', params: data, token: true})
}





























// ================================================================================================
// 商户
// ================================================================================================

// 获取商户分页
export function apiV1GetMerchantsPage(data) {
    return request({ url: `/api/admin/merchant/merchants`, method: 'get', params: data, token: true })
}
// 获取商户对象
export function apiGetMerchantsObject(data) {
    return request({        url: `/api/admin/merchant/merchants/${data.merchant_id}`,        method: 'get',        params: data,        token: true,    })
}
// 添加商户
export function apiInsertMerchants(data) {
    return request({        url: `/api/admin/merchant/merchant`,        method: 'post',        data,        token: true,    })
}
// 修改商户
export function apiUpdateMerchants(data) {
    return request({        url: `/api/admin/merchant/merchants/${data.merchant_id}`,        method: 'put',        data,        token: true,    })
}
// 删除商户
export function apiDeleteMerchants(data) {
    return request({        url: `/api/admin/merchant/merchants/${data.merchant_id}`,        method: 'delete',        data,        token: true,    })
}
// 获取商户pair
export function apiGetMerchantsPairs(data) {
    return request({        url: `/api/admin/merchant/merchants-pairs`,        method: 'get',        params: data,        token: true,    })
}





// ================================================================================================
// 商户 V1
// ================================================================================================

// 获取商户分页
export function apiV1GetMerchantShopPage(data) {
    return request({ url: `/api/admin/v1/merchant/merchant-shops`, method: 'get', params: data, token: true })
}

export function apiV1GetMerchantShopPairs(data) {
    return request({url: `/api/admin/v1/merchant/merchant-shop-pairs`, method: 'get', token: true})
}





// ================================================================================================

// 获取 商户海关编码设置
export function apiGetMerchantCustomsCodeConfig(data) {
    return request({
        url: `/api/admin/merchant/merchants/${data.merchant_id}/customs-code-configs`,
        method: 'get',
        params: data,
        token: true,
    })
}
// 设置 商户海关编码设置
export function apiSetMerchantCustomsCodeConfig(data) {
    return request({
        url: `/api/admin/merchant/merchants/${data.merchant_id}/customs-code-config`,
        method: 'post',
        data,
        token: true,
    })
}


// 获取 商户物流商编码设置
export function apiGetMerchantLogisticsCodeConfig(data) {
    return request({
        url: `/api/admin/merchant/merchants/${data.merchant_id}/logistics-code-configs`,
        method: 'get',
        params: data,
        token: true,
    })
}
// 设置 商户物流商编码设置
export function apiSetMerchantLogisticsCodeConfig(data) {
    return request({
        url: `/api/admin/merchant/merchants/${data.merchant_id}/logistics-code-config`,
        method: 'post',
        data,
        token: true,
    })
}


// ================================================================================================


// 获取 商户权限
export function apiGetMerchantSystemPermission(data) {
    return request({
        url: `/api/admin/merchant/merchants/${data.merchant_id}/system-permissions`,
        method: 'get',
        params: data,
        token: true,
    })
}
// 设置 商户权限
export function apiSetMerchantSystemPermission(data) {
    return request({
        url: `/api/admin/merchant/merchants/${data.merchant_id}/system-permission`,
        method: 'post',
        data,
        token: true,
    })
}


// ================================================================================================


// 获取 商户关区编码 结构对
export function apiGetMerchantCustomsCodePairs(data) {
    return request({
        url: `/api/admin/merchant/merchants/${data.merchant_id}/customs-code-pairs`,
        method: 'get',
        params: data,
        token: true,
    })
}
// 获取 商户物流商编码 结构对
export function apiGetMerchantLogisticsCodePairs(data) {
    return request({
        url: `/api/admin/merchant/merchants/${data.merchant_id}/logistics-code-pairs`,
        method: 'get',
        params: data,
        token: true,
    })
}




// ================================================================================================



// ================================================================================================
// 店铺
// ================================================================================================

// 获取店铺分页
export function apiGetShopPage(data) {
    return request({
        url: `/api/admin/merchant/shops`,
        method: 'get',
        params: data,
        token: true,
    })
}

// 获取店铺对象
export function apiGetShopObject(data) {
    return request({
        url: `/api/admin/merchant/shops/${data.shop_id}`,
        method: 'get',
        params: data,
        token: true,
    })
}

// 添加店铺
export function apiInsertShop(data) {
    return request({
        url: `/api/admin/merchant/shop`,
        method: 'post',
        data,
        token: true,
    })
}

// 修改店铺
export function apiUpdateShop(data) {
    return request({
        url: `/api/admin/merchant/shops/${data.shop_id}`,
        method: 'put',
        data,
        token: true,
    })
}

// 删除店铺
export function apiDeleteShop(data) {
    return request({
        url: `/api/admin/merchant/shops/${data.shop_id}`,
        method: 'delete',
        data,
        token: true,
    })
}


// 获取 店铺配置管理
export function apiGetConfigManagement(data) {
    return request({
        url: `/api/admin/merchant/shops/${data.shop_id}/config-management/${data.plugin_type}`,
        method: 'get',
        params: data,
        token: true,
    })
}

// 设置 店铺配置管理
export function apiSetConfigManagement(data) {
    return request({
        url: `/api/admin/merchant/shops/${data.shop_id}/config-management/${data.plugin_type}`,
        method: 'post',
        data,
        token: true,
    })
}


// ================================================================================================
// 店铺申报配置
// ================================================================================================

// 获取 申报配置 分页
export function apiGetShopDeclarationConfigPage(data) {
    return request({url: `/api/admin/merchant/shops/${data.shop_id}/declaration-configs`, method: 'get', params: data, token: true})
}
// 获取 申报配置 对象
export function apiGetShopDeclarationConfigObject(data) {
    return request({url: `/api/admin/merchant/shops/${data.shop_id}/declaration-configs/${data.config_id}`, method: 'get', params: data, token: true})
}
// 添加 申报配置
export function apiInsertShopDeclarationConfig(data) {
    return request({url: `/api/admin/merchant/shops/${data.shop_id}/declaration-config`, method: 'post', data, token: true})
}
// 修改 申报配置
export function apiUpdateShopDeclarationConfig(data) {
    return request({url: `/api/admin/merchant/shops/${data.shop_id}/declaration-configs/${data.config_id}`, method: 'put', data, token: true})
}
// 删除 申报配置
export function apiDeleteShopDeclarationConfig(data) {
    return request({url: `/api/admin/merchant/shops/${data.shop_id}/declaration-configs/${data.config_id}`, method: 'delete', data, token: true})
}

// ------------------------------------------------------------------------------------------------

// 获取 店铺申报配置内指定类别的插件配置
export function apiGetShopPluginConfigByDeclarationConfigId(data) {
    return request({url: `/api/admin/merchant/shops/${data.shop_id}/declaration-configs/${data.config_id}/${data.plugin_type}/${data.plugin_code}`, method: 'get', params: data, token: true})
}
// 设置 店铺申报配置内指定类别的插件配置
export function apiSetShopPluginConfigByDeclarationConfigId(data) {
    return request({ url: `/api/admin/merchant/shops/${data.shop_id}/declaration-configs/${data.config_id}/${data.plugin_type}/${data.plugin_code}`, method: 'post', data, token: true})
}



// ================================================================================================
// 店铺设置 废弃
// ================================================================================================


// 获取 店铺设置
export function apiGetShopConfig(data) {
    return request({
        url: `/api/admin/merchant/shops/${data.shop_id}/config`,
        method: 'get',
        params: data,
        token: true,
    })
}

// 设置 店铺设置
export function apiSetShopConfig(data) {
    return request({
        url: `/api/admin/merchant/shops/${data.shop_id}/config`,
        method: 'post',
        data,
        token: true,
    })
}


// ================================================================================================



// ================================================================================================
// 店铺设置
// ================================================================================================

// 获取 店铺设置
export function apiGetShopSetting(data) {
    return request({url: `/api/admin/merchant/shops/${data.shop_id}/settings`, method: 'get', params: data, token: true})
}
// 设置 店铺设置
export function apiSetShopSetting(data) {
    return request({url: `/api/admin/merchant/shops/${data.shop_id}/settings`, method: 'post', data, token: true})
}







// ================================================================================================
// 废弃
// ================================================================================================

// 获取 店铺指定类别的配置
export function apiGetShopPluginConfig(data) {
    return request({
        url: `/api/admin/merchant/shops/${data.shop_id}/plugin-config/${data.plugin_type}/${data.plugin_code}`,
        method: 'get',
        params: data,
        token: true,
    })
}
// 设置 店铺指定类别的配置
export function apiSetShopPluginConfig(data) {
    return request({
        url: `/api/admin/merchant/shops/${data.shop_id}/plugin-config/${data.plugin_type}/${data.plugin_code}`,
        method: 'post',
        data,
        token: true,
    })
}
