import NavConfig from '@/config/nav.js'

var NavUtils = {
// set(key, value) {
//     // try {
//         window.localStorage.setItem(GlobalConfig.storagePrefix + key, JSON.stringify(value))
//     // } catch (e) {
//     // }
// },
// get(key, defaultValue = undefined) {
//     try {
//         let keyValue = window.localStorage.getItem(GlobalConfig.storagePrefix + key)
//         return (JSON.parse(keyValue) || (defaultValue || undefined))
//     } catch (e) {
//         console.log(e)
//     }
// },
// remove(key) {
//     window.localStorage.removeItem(GlobalConfig.storagePrefix + key)
// },

  getNavItem(index) {
    return NavConfig[index]
  },
}

export default NavUtils