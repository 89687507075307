<template>
  <el-tabs
    v-model="editableTabsValue"
    type="border-card"
    class="ep-history-tabs"
    @tab-change="onTabChange"
    @tab-remove="onTabRemove"
  >
    <el-tab-pane
      v-for="item in editableTabs"
      :key="item.name"
      :name="item.name"
      :closable="editableTabs.length > 1"
    >
    <template #label>
      <span class="m-state-icon" :style="getStateIconColor(item.name == editableTabsValue)"/><span>{{ item.title }}</span>
    </template>

  </el-tab-pane>
  </el-tabs>
</template>

<script>
import NavUtils from '@/utils/nav-utils.js'

export default {
  name: "ep-history-tabs",
  components: {
  },  
  props: {
    // size: {
    //   type: String,
    //   default: 'default'
    // },
  },
  data() {
    return {
      editableTabsValue: '',
      // editableTabs: [],
      // editableTabsValue: '/v1/declaration/logistics/list',
      // editableTabs: [
      //   {
      //     title: 'Tab 1',
      //     name: '1',
      //     content: 'Tab 1 content',
      //   },
      //   {
      //     title: 'Tab 2',
      //     name: '2',
      //     content: 'Tab 2 content',
      //   },
      // ],
    };
  },
  computed: {
    // Popover标题
    _CurrentPage() {
      return this.$store.getters.getCurrentPage
    },
    editableTabs() {
      return this.$store.getters.getPages
    },
  },
  watch: {
    _CurrentPage(newValue, oldValue) {
      // console.log(this._CurrentPage)
      // console.log(this.$store.getters.getPages)
      this.editableTabsValue = this._CurrentPage
    },
    // _Pages(newValue, oldValue) {
    //   console.log(this._Pages)
    //   this.editableTabs = this._Pages
    // },
  },
  methods: {
    onMenuItemSelected(index, indexPath, item, routeResult) {
      // console.log(index); console.log(indexPath); console.log(item); console.log(routeResult); 
      this.$store.dispatch({type: 'changePage', item: {name: index}})
    },
    onTabChange(name) {
      var currentNavItem = NavUtils.getNavItem(name)
      if (currentNavItem != undefined) {
        // 初始化Nav1 index
        this.$store.dispatch({type: 'setNav1Index', index: currentNavItem.nav1Index})

        // 初始化Nav2 index
        this.$store.dispatch({type: 'setNav2Index', index: name})
      }
      this.$router.replace({ path: name })
    },
    onTabRemove(name) {
      this.$store.dispatch({type: 'removePage', name})
    },
    //
    getStateIconColor(isCurrentPage) {
      return 'background:#' + (isCurrentPage ? '409EFF' : '909399');
    },
  },
  mounted() {
    this.$nextTick(() => {
    })
  },
};
</script>

<style scoped>
.m-state-icon {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 8px;
  display: inline-block;
  clear: both;
  line-height: 38px;
  text-align: center;
}
</style>
