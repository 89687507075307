import { request } from '@/utils/request-utils.js';


// ================================================================================================
// 权限
// ================================================================================================

// 登录验证
export function apiV1AuthLogin(data) {
    return request({url: '/api/merchant/v1/auth/authorize', method: 'post', data})
}
// 修改密码
export function apiV1ChangePassword(data) {
    return request({url: '/api/merchant/v1/auth/change-password', method: 'post', data, token: true})
}

// ================================================================================================



