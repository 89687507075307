<template>
  <div class="m-main">
    <!-- -->
    <div class="m-header">
      <div class="m-content-inner">
        <a target="_blank" href="."><img class="logo" src="/static/images/full-logo.png"></a>
        <span class="welcome">商户 - 店铺选择</span>
      </div>
    </div>
    <!-- -->
    <!-- -->
    <div class="m-content">
      <div class="m-content-inner">
        <el-card class="box-card">
          <template #header>
            <div class="m-card-header">
              <span>
                <el-avatar src="/static/images/headlogo.png" :size="36" />
                <span class="m-card-header-username"> 账号名称： {{ userName }}</span>
              </span>
              <el-button class="m-card-header-logout" type="primary" size="default" @click="onClickLogout">登出</el-button>
            </div>
          </template>
          <el-row :span="24">
            <el-col v-for="(item) in this.merchantShopPairs" :key="item.shop_id" class="m-shop-card" :span="7" @click="onShopCardClick(item)">
              <div>{{ item.shop_name }}</div>
              <div class="ep-cell-subtitle" style="padding:10px 0">店铺编码：{{ item.shop_code }}</div>
              <div class="ep-cell-subtitle" style="padding:0">有效期至：{{ item.expire_time_desc }}</div>
              <div style="padding:10px 0"><SourceCodeTag :value="item.source_code" /></div>
            </el-col>
          </el-row>
        </el-card>
      </div>
    </div>
    <!-- -->
    <!-- -->
    <div class="m-footer">
      <div class="m-content-inner">
        <div class="m-copyright">Copyright © {{ nowFullYear }} 广州佳兴信息科技有限公司
          <el-link type="primary" href="https://beian.miit.gov.cn" target="_blank" style="font-size:12px;">粤ICP备14082142号</el-link>
        </div>
      </div>
    </div>
    
    <!-- -->
  </div>
</template>

<script>
import SourceCodeTag from '@/components/v1/jxgocs/tags/SourceCodeTag'
import AuthUtils from '@/utils/auth-utils.js';
import { apiV1GetShopPairs } from '@/api/merchant.js';
// import GlobalConfig from '@/config/index.js'

export default {
  components: {
    SourceCodeTag,
  },
  data() {
    return {
      merchantShopPairs: [],
    };
  },
  emits: {},
  computed: {
    nowFullYear() {
      return new Date().getFullYear()
    },
    userName() {
      return AuthUtils.getUserName()
    }
  },
  methods: {
    // 点击登出
    onClickLogout() {
      AuthUtils.userLogout()
      this.$router.push({ path: '/login'});
    },
    // 选择店铺
    onShopCardClick(item) {
      // console.log(shop_id); console.log(shop_name)

      AuthUtils.resetMerchantId()
      AuthUtils.setCurrentShopId(item.shop_id)
      AuthUtils.setCurrentShopName(item.shop_name)
      AuthUtils.setCurrentShopSourceCode(item.source_code)
      

      this.$router.push({ path: '/'});
    },


    // ================================================================================================

    // 获取商户店铺信息
    getMerchantShopPairsData() {
      apiV1GetShopPairs({
        merchant_id: AuthUtils.getUserId()
      }).then((response) => {
        this.merchantShopPairs = response.data.data
      })
    },

  },
  mounted() {
    // this.$store.commit('UPDATE_TOKEN', 'test123')
    // console.log(this.$store)
    this.$nextTick(() => {
      this.getMerchantShopPairsData()
    })
  },
}

</script>

<style scoped>
.m-header {
  background: #fff;
  text-align: left;
  line-height: 90px;
  height: 90px;
  position: relative;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
}
.m-wrap {
  overflow: scroll;
}
.m-content-inner{
  width: 1000px;
  margin: 0 auto;
  position: relative;
}
.m-header .logo {
  position: absolute;
  top: 23px;
  height: 40px;
  width: auto;
}
.m-header .welcome {
  font-size: 24px;
  color: #828282;
  padding-left: 15px;
  margin-left: 142px;
  margin-right: 10px;
  border-left: 1px solid #c9d0d3;
}
.m-content {
  /* background:#f5f5f6; */
  height: 500px;
  min-width: 1000px;
  margin-top: 30px;
}
.m-copyright {
  text-align: center;
  margin: 0 auto;
  border-top: 1px solid #e8e8e8;
  padding: 15px 0;
  font-size: 12px;
  color: #828282;
  line-height: 24px;
}


.m-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.m-card-header-username {
  line-height: 36px;
  height: 36px;
  vertical-align: top;
}
.m-shop-card {
  border: 1px solid #e5e5e5;
  border-top: 2px solid #59f;
  min-height: 100px;
  padding: 10px;
  margin: 10px;
  cursor: pointer;
}
</style>