<template>
  <div class="m-main">
    <!-- -->
    <div class="m-header">
      <div class="m-content-inner">
        <a target="_blank" href="."><img class="logo" src="/static/images/full-logo.png"></a>
        <span class="welcome">商户后台登录</span>
      </div>
    </div>
    <!-- -->
    <!-- -->
    <div class="m-content">
      <div class="m-content-inner">
        <div class="m-account-left">
          <img class="m-account-left-img" src="/static/images/login_left.png" />
        </div>
        <div class="m-account-right">
          <div class="m-form-wrap">
            <div class="m-account-title">密码登录</div>
            <el-form :model="formModel" :rules="formRules" ref="loginForm" label-width="0px">
              
             <el-form-item prop="account">  
               <!-- @keyup.enter="onEnterKeyUp" -->
                <el-input type="text" size="default" v-model="formModel.account" auto-complete="off" placeholder="请输入登录账号"></el-input>
              </el-form-item>
              <el-form-item prop="password">
                <el-input type="password" size="default" v-model="formModel.password" auto-complete="off" placeholder="请输入登录密码"></el-input>
              </el-form-item>
              <!-- <el-checkbox v-model="checked" checked class="remember">记住密码</el-checkbox> -->
              <!-- <el-form-item> -->
                <el-button type="primary" class="m-login-btn" @click="formSubmit" :loading="logining">登录</el-button>
              <!-- </el-form-item> -->
            </el-form> 
          </div>
        </div>
      </div>
    </div>
    <!-- -->
    <!-- -->
    <div class="m-footer">
      <div class="m-content-inner">
        <div class="m-copyright">Copyright © {{ nowFullYear }} 广州佳兴信息科技有限公司
          <el-link type="primary" href="https://beian.miit.gov.cn" target="_blank" style="font-size:12px;">粤ICP备14082142号</el-link>
        </div>
      </div>
    </div>
    
    <!-- -->
  </div>
</template>

<script>
import AuthUtils from '@/utils/auth-utils.js';
import { apiV1AuthLogin } from '@/api/auth.js';
// import GlobalConfig from '@/config/index.js'

export default {
  data() {
    return {
      logining: false,
      formModel: {
        account: '',
        password: ''
      },
      formRules: {
        // account: [{ required: true, message: '请输入账号', trigger: 'blur' }],
        // checkPass: [{ required: true, message: '请输入密码', trigger: 'blur' }]
      },
    };
  },
  emits: {},
  computed: {
    nowFullYear() {
      return new Date().getFullYear()
    }
  },
  methods: {
    onEnterKeyUp() {
      // console.log(event)
      this.formSubmit()
    },
    formSubmit() {
      let account = this.formModel.account;
      let password = this.formModel.password;

      this.logining = true
      apiV1AuthLogin({
        login_name: account,
        login_password: password,
      }).then((response) => {
        // console.log(response); return

        this.logining = false
        if (response.data.access_token) {
          AuthUtils.userLogin({
            token: response.data['access_token'],
            user_id: response.data['user_id'],
            user_type: response.data['user_type'],
            ref_id: response.data['ref_id'],
            name: response.data['name'],
            is_super: response.data['is_super'],
            sys_permissions: response.data['sys_permissions'],
          })

          this.$router.push({ path: '/'})
        } else {
          this.$message.error({
            message: '用户名或密码错误',
            showClose: true,
          })
        }
      })
    }
  },
  mounted() {
  },
}

</script>

<style scoped>
.m-header {
  background: #fff;
  text-align: left;
  line-height: 90px;
  height: 90px;
  position: relative;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
}
.m-wrap {
  overflow: scroll;
}
.m-content-inner{
  width: 1000px;
  margin: 0 auto;
  position: relative;
}
.m-header .logo {
  position: absolute;
  top: 23px;
  height: 40px;
  width: auto;
}
.m-header .welcome {
  font-size: 24px;
  color: #828282;
  padding-left: 15px;
  margin-left: 142px;
  margin-right: 10px;
  border-left: 1px solid #c9d0d3;
}
.m-content {
  /* background:#f5f5f6; */
  height: 500px;
  min-width: 1000px;
  margin-top: 30px;
}
.m-form-wrap {
  position: relative;
  width: 340px;
  height: 240px;
  box-shadow: 0 2px 15px rgba(0,0,0,.15);
  background-color: #fff;
  padding: 120px 80px;
}
.m-account-left {
  width: 500px;
  height: 100%;
  float: left;
}
.m-account-left-img {
  width: 500px;
}
.m-account-right {
  width: 500px;
  height: 100%;
  float: right;
}
.m-account-title {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 30px;
  text-align: left;
}
.m-login-btn {
  width: 100%;
  height: 40px;
  margin-top: 10px;
}
.m-copyright {
  text-align: center;
  margin: 0 auto;
  border-top: 1px solid #e8e8e8;
  padding: 15px 0;
  font-size: 12px;
  color: #828282;
  line-height: 24px;
}
</style>