<template>
  <div class="main-nav-wrap">
    <!-- 其他窗体 START -->
    <!-- 修改密码View -->
    <PasswordEditView ref="refPasswordEditView" />
    <!-- 其他窗体 END -->
    <div class="main-nav-logo">
      <a href="/"><img class="main-nav-logo-img" src="/static/images/full-logo.png" /></a>
    </div>
    <div class="m-main-nav-toolbar-wrap">
      <div style="flex-basis: 650px;">
      <!-- background-color="#f2f2f2" -->
        <el-menu :default-active="activeIndex" mode="horizontal" @select="onNav1Selected">
          <el-menu-item index="index">首页</el-menu-item>
          <el-menu-item index="goods">商品</el-menu-item>
          <el-menu-item index="trade">订单</el-menu-item>
          <el-menu-item index="declaration">申报</el-menu-item>
          <el-menu-item index="fenxiao" v-permission="'C0001'">分销</el-menu-item>
          <el-menu-item index="finance" v-permission:or="'A0014,C0001'">账单</el-menu-item>
          <el-menu-item index="system">系统</el-menu-item>
        </el-menu>
      </div>

      <!-- <div style="flex-basis: auto;">
        <main-merchant-shop-picker @shop-change="onShopChanged"/>
      </div> -->
      <!-- 商户店铺选择 START -->
    
      <!-- 商户店铺选择 END -->


      <div>
        <!-- <div style="width:300px;flex-basis: 300px;">Admin</div> -->
        <el-menu class="main-nav-toolbar-el-menu" mode="horizontal" :ellipsis="false" menu-trigger="click" @select="onUserMenuSelect" >
          <el-menu-item index="shop-name">
            <span style="line-height:20px;padding:10px"><SourceCodeTag :value="shopSourceCode" /></span>{{ shopName }}<el-icon class="el-icon--right"><ArrowDown /></el-icon>
          </el-menu-item>
          <el-sub-menu index="user-name">
            <template #title>
              <div style="height: 56px; line-height: 56px;" >
                <el-avatar src="/static/images/headlogo.png" :size="28" style="margin: 14px 10px;" />
                <span class="main-nav-toolbar-username"> {{ userName }}</span>
              </div>
            </template>
            <el-menu-item index="edit-password">修改密码</el-menu-item>
            <el-menu-item index="logout">登出</el-menu-item>
          </el-sub-menu>
        </el-menu>
      </div>

    </div>
  </div>
</template>

<script>
import permission from '@/directive/PermissionDirective.js'
import { ArrowDown } from '@element-plus/icons-vue'
import PasswordEditView from '@/views/v1/common/profile/PasswordEditView'
// import MainMerchantShopPicker from '@/components/v1/merchant/MainMerchantShopPicker'
import SourceCodeTag from '@/components/v1/jxgocs/tags/SourceCodeTag'
import AuthUtils from '@/utils/auth-utils.js'

export default {
  components: {
    ArrowDown,
    PasswordEditView,
    // MainMerchantShopPicker,
    SourceCodeTag,
  },
  directives: {
    permission
  },
  data () {
    return {
      // activeIndex: '',
    }
  },
  computed: {
    shopSourceCode() {
      return AuthUtils.getCurrentShopSourceCode()
    },
    shopName() {
      return AuthUtils.getCurrentShopName()
    },
    userName() {
      return AuthUtils.getUserName()
    },
    activeIndex() {
      return this.$store.getters.getNav1Index
    },
  },
  methods: {
    // 用户菜单-选择时触发
    onUserMenuSelect(key, keyPath) {
      // console.log(key, keyPath)
      switch (key) {
        case 'shop-name':
          this.$router.push({ path: '/v1/shop-picker'});
          break;
        case 'edit-password':
          this.$refs.refPasswordEditView.showView()
          break;
        case 'logout':
          AuthUtils.userLogout()
          this.$router.push({ path: '/login'});
          break;
      }
    },
    //
    onNav1Selected(index) {
      // console.log(index)
      this.$store.dispatch({type: 'setNav1Index', index: index})
      if (index == 'index') {
        this.$router.replace({path: '/'})
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      // this.activeIndex = this.$route.meta.mainNavIndex
    })
  }
}
</script>

<style scoped>
.main-nav-wrap {
  background: white;
  width: 100%;
  height: 58px;
  /* box-shadow: 0 0 1px 0px rgba(0,0,0,0.3), 0 0 6px 2px rgba(0,0,0,0.15); */
}
.main-nav-logo {
  height: 100%;
  width: 219px;
  float: left;
  /* border-right: 1px solid #dcdee0; */
}
.main-nav-logo-img {
  height: 40px;
  padding: 10px 40px;
}
.m-main-nav-toolbar-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}
</style>
