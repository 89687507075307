<template>
  <el-container>
    <el-header height="60px">
      <main-nav-view/>
    </el-header>
    <el-container>
      <el-aside class="m-ep-menu-wrap" width="220px">
        <!-- background-color="#323232" text-color="#fff" active-text-color="#5cc2cf"  -->
        <el-menu router class="m-ep-menu" :collapse-transition="true" :collapse="false"
          :default-openeds="defaultOpeneds" :default-active="nav2Index" @select="onNav2Selected">

          <el-sub-menu index="/v1/goods" v-show="nav1Index == 'goods'">
            <template #title><el-icon><Goods/></el-icon><span>商品</span></template>
            <el-menu-item index="/v1/goods/goods/list">商品列表</el-menu-item>
            <el-menu-item index="/v1/goods/goods/add">商品添加</el-menu-item>
            <el-menu-item index="/v1/goods/goods/upload">商品上传</el-menu-item>
            <el-menu-item index="/v1/goods/combine-goods/list" v-permission="'C0002'">组合商品列表</el-menu-item>
            <el-menu-item index="/v1/goods/cainiao-goods-quantity/list" v-permission="'A0013'">云仓货品库存</el-menu-item>
          </el-sub-menu>
          <el-sub-menu index="/v1/goods4" v-show="nav1Index == 'goods'" v-permission="'D0001'">
            <template #title><el-icon><Goods/></el-icon><span>货品管理（新）</span></template>
            <el-menu-item index="/v1/goods/goods-item/list">货品列表（新）</el-menu-item>
            <el-menu-item index="/v1/goods/goods-item/add">货品添加（新）</el-menu-item>
          </el-sub-menu>

          <el-sub-menu index="/v1/fenxiao" v-show="nav1Index == 'fenxiao'">
            <template #title><el-icon><List/></el-icon><span>分销信息</span></template>
            <el-menu-item index="/v1/fenxiao/fenxiao-relation/list">分销合作关系</el-menu-item>
          </el-sub-menu>
          <el-sub-menu index="/v1/fenxiao2" v-show="nav1Index == 'fenxiao'">
            <template #title><el-icon><Goods/></el-icon><span>分销货品</span></template>
            <el-menu-item index="/v1/fenxiao/goods/market">分销货品列表</el-menu-item>
          </el-sub-menu>
          <el-sub-menu index="/v1/fenxiao3" v-show="nav1Index == 'fenxiao'">
            <template #title><el-icon><List/></el-icon><span>分销商</span></template>
            <el-menu-item index="/v1/fenxiao/purchase/goods-item">供货关系管理</el-menu-item>
            <el-menu-item index="/v1/fenxiao/purchase/list">分销采购表</el-menu-item>
          </el-sub-menu>

          <el-sub-menu index="/v1/trade" v-show="nav1Index == 'trade'">
            <template #title><el-icon><List/></el-icon><span>订单管理</span></template>
            <el-menu-item index="/v1/trade/order/list">订单列表</el-menu-item>
          </el-sub-menu>

          <el-sub-menu index="/v1/declaration" v-show="nav1Index == 'declaration'">
            <template #title><el-icon><Checked/></el-icon><span>申报管理</span></template>
            <el-menu-item index="/v1/declaration/payment/list">支付申报</el-menu-item>
            <el-menu-item index="/v1/declaration/customs/list">订单申报</el-menu-item>
            <el-menu-item index="/v1/declaration/logistics/list">物流申报</el-menu-item> 
            <el-menu-item index="/v1/declaration/clearance/list">清关申报</el-menu-item> <!-- v-if="$$auth.hasPermission('A0001')" -->
            <el-menu-item index="/v1/declaration/warehouse/list">仓储申报</el-menu-item> <!-- v-if="$$auth.hasPermission('A0008')" -->
            <!-- <el-menu-item index="/v1/declaration/logistics/waybill">运单处理</el-menu-item>  -->
          </el-sub-menu>
          <el-sub-menu index="/v1/declaration2" v-show="nav1Index == 'declaration'" v-permission="'A0010'">
            <template #title><el-icon><LocationFilled/></el-icon><span>推送管理</span></template>
            <el-menu-item index="/v1/declaration/push/notice">通知推送</el-menu-item>
          </el-sub-menu>
          <el-sub-menu index="/v1/declaration-push" v-show="nav1Index == 'declaration'">
            <template #title><el-icon><LocationFilled/></el-icon><span>运单信息管理</span></template>
            <el-menu-item index="/v1/declaration/logistics/waybill">运单处理</el-menu-item>
          </el-sub-menu>

          <!--  -->
          <el-sub-menu index="/v1/finance1" v-show="nav1Index == 'finance'">
            <template #title><el-icon><Operation/></el-icon><span>账户管理</span></template>
            <el-menu-item index="/v1/finance/account/list">账户信息</el-menu-item>
          </el-sub-menu>

          <el-sub-menu index="/v1/system" v-show="nav1Index == 'system'"><!-- v-permission="['A0001']" -->
            <template #title><el-icon><Operation/></el-icon><span>硬件</span></template>
            <el-menu-item index="/v1/system/ukey/info">UKEY信息</el-menu-item>
          </el-sub-menu>
          <el-sub-menu index="/v1/system2" v-show="nav1Index == 'system'"><!-- v-permission="['A0001']" -->
            <template #title><el-icon><Operation/></el-icon><span>图片管理</span></template>
            <el-menu-item index="/v1/system/image/list">图片列表</el-menu-item>
          </el-sub-menu>

        </el-menu>
      </el-aside>
      <el-main style="padding:0">
        <EpHistoryTabs />
        <el-scrollbar class="m-scrollbar">
          <!-- <router-view/> -->
          <router-view v-slot="{ Component }">
            <keep-alive>
              <component :is="Component" />
            </keep-alive>
          </router-view>
        </el-scrollbar>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
// 62px
import permission from '@/directive/PermissionDirective.js'
import { Menu, Goods, List, Checked, UploadFilled, Operation, LocationFilled } from '@element-plus/icons-vue'
import MainNavView from '@/views/v1/common/dashboard/MainNavView'
import EpHistoryTabs from '@/components/v1/common/dashboard/EpHistoryTabs'
import NavUtils from '@/utils/nav-utils.js'
import AuthUtils from '@/utils/auth-utils.js'


export default {
  name: 'dashboard-page',
  components: {
    Menu, Goods, List, Checked, UploadFilled, Operation, LocationFilled,
    MainNavView,
    EpHistoryTabs,
  },
  directives: {
    permission
  },
  data() {
    return {
      // defaultActiveIndex: '',
      defaultOpeneds: [
        '/v1/goods', '/v1/goods4',
        '/v1/fenxiao', '/v1/fenxiao2', '/v1/fenxiao3',
        '/v1/trade',
        '/v1/declaration', '/v1/declaration2', '/v1/declaration-push',
        '/v1/finance1',
        '/v1/system', '/v1/system2',
      ],
    }
  },
  computed: {
    // asideWidth () { :width="asideWidth"
    //   return (this.$store.getters.isNavMenuCollapse) ? '64px' : '220px'
    // }
    nav1Index() {
      return this.$store.getters.getNav1Index
    },
    nav2Index() {
      return this.$store.getters.getNav2Index
    },
  },
  methods: {
    onMenuItemSelected(index, indexPath, item, routeResult) {
      // console.log(index); console.log(indexPath); console.log(item); console.log(routeResult);
      this.$store.dispatch({type: 'addPage', item: {title: this.tabTitle.get(index), name: index,}})
    },
    onNav2Selected(index) {
      // console.log(index)
      var navItem = NavUtils.getNavItem(index)
      this.$store.dispatch({type: 'setNav2Index', index: index})
      this.$store.dispatch({type: 'addPage', item: {title: navItem.title, name: index,}})
    },
  },
  mounted() {
    this.$nextTick(() => {
      // console.log(this.$route)
      var currentPath = this.$route.path
      var currentNavItem = NavUtils.getNavItem(currentPath)
      if (currentNavItem != undefined) {
        // 初始化Nav1 index
        this.$store.dispatch({type: 'setNav1Index', index: currentNavItem.nav1Index})

        // 初始化Nav2 index
        this.$store.dispatch({type: 'setNav2Index', index: currentPath})

        // 初始化历史栏
        this.$store.dispatch({type: 'addPage', item: { title: currentNavItem.title, name: currentPath} })

      }

      // this.defaultActiveIndex = this.$route.meta.asideNavIndex
      // this.defaultOpeneds = this.$route.meta.asideNavOpeneds
    })
  }
}
</script>

<style scoped>
.el-header {
  padding: 0px;
  border-top: 2px solid #5CC2CF;
  box-shadow: 0 0 1px 0px rgba(0,0,0,0.3), 0 0 6px 2px rgba(0,0,0,0.15);
  width: 100%;
  background: white;
  z-index: 3000;
}
.el-container {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
}
.m-scrollbar {
  height: calc(100vh - 101px);
}
</style>
