<template>
  <el-dialog v-model="viewVisible" :append-to-body="false" :close-on-click-modal="false" width="800px" @open="onDialogOpen">
    <template #header>
      <i class="el-icon-edit"></i>获取授权地址
    </template>

    <el-card class="es-dialog-card">
      <el-form ref="form" :model="formModel" label-width="100px" v-loading="isLoading" status-icon :rules="formRules">
        <el-form-item label="授权地址：" prop="url">
          <el-input type="textarea" v-model="formModel.url" :rows="5" readonly />
        </el-form-item>
      </el-form>
    </el-card>
    
    <template #footer>
      <el-button type="default" icon="el-icon-close" @click="viewVisible = false">关闭</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { apiPlatformPddGetOauthUrl } from '@/api/platform_pdd.js';

export default {
  name: 'oauth-url-show-view',
  components: {
    // EsSlideDialog,
  },
  props: [],
  data() {
    return {
      editMode: 'insert',
      viewVisible: false,
      isLoading: false,

      // order_sn: '',
      // order_id: '',
      formModel: {
      
      //   customs_app_code: '',
      //   payment_app_code: '',
      //   logistics_app_code: '',

        url: '',
      },

      // shop_id: '',
      

      // 检验规则
      formRules: {
        // name: [{required: true, message: '请输入中文名', trigger: 'blur' }, {max: 20, message: '中文名长度不超过 20 个字符', trigger: 'blur'}],
        // staff_code: [{required: true, message: '员工编码', trigger: 'blur'}],
      },
      // 拷贝规则
      cloneFields: [
        // 'name', 'name_en', 'staff_code', 'campus_id', 'mobile', 'email', 'department_id', 'staff_type_id',
        // {key: 'sex', default: 0},
      ],
    }
  },
  computed: {
  },
  methods: {
    // 显示view
    showView({ editMode, shop_id } = {}) {
      this.editMode = editMode || 'insert'
      this.shop_id = shop_id
      this.viewVisible = true
    },
    
    // 当窗体打开时
    onDialogOpen() {
      this.getOauthUrl()
    },

    // ===================================================================================================

    // 获取校区pair
    getOauthUrl() {
      apiPlatformPddGetOauthUrl({
        shop_id: this.shop_id,
      }).then((res) => {
        // console.log(res.data.data)
        this.formModel.url = res.data.data;
      })
    },
  },
  mounted() {
    
  }
};
</script>

<style scoped>
.el-form {
  /* width: 800px; */
  margin-left: auto;
  margin-right: auto;
}
</style>
