<template>
  <el-container>
    <el-header>
      <!-- 其他窗体 START -->
      <OauthUrlShowView ref="oauthUrlShowView" />
      <!-- 其他窗体 END -->
      <div class="partner-name">
        欢迎，{{ merchantData.merchant_name }}
      </div>
    </el-header>  
    <el-main>
      <el-row>
        <el-col :span="18" class="m-box-card"><div class="grid-content bg-purple"></div>
          <el-card class="m-box-card">
            <template #header>
              <i class="el-icon-office-building el-icon--left"></i><span>商户店铺信息</span>
              <el-button class="card-more-button" text><i class="el-icon-more el-icon--left"></i></el-button>
            </template>
            <template v-if="permissionTree.length == 0">
              <span>暂无数据</span>
            </template>
            <template v-else>
              
              
              <div class="m-merchant-item" v-for="(merchant) in permissionTree" :key="merchant.merchant_id">
                <div class="m-merchant-item-title">
                  <span class="m-merchant-name" style="padding-right:5px;">{{ merchant.merchant_name }}</span>
                  <el-tag v-if="merchantData.oms_service_version == 1">基础版</el-tag>
                  <el-tag v-if="merchantData.oms_service_version == 2">标准版</el-tag>
                  <el-tag v-if="merchantData.oms_service_version == 3">集成版</el-tag>
                  <el-tag v-if="merchantData.oms_service_version == 4">企业版</el-tag>
                  <!-- <span class="m-merchant-code">商户编码：{{ merchant.merchant_code }}</span> -->
                </div>
                <div class="m-merchant-panel">

                  <el-row :span="24">
                    <el-col v-for="(item) in merchant.children" :key="item.shop_id" class="m-shop-card" :span="7">
                      <div>{{ item.shop_name }}</div>
                      <div class="ep-cell-subtitle" style="padding:10px 0">店铺编码：{{ item.shop_code }}</div>
                      <div class="ep-cell-subtitle" style="padding:0">有效期至：{{ item.expire_time_desc }}</div>
                      <div style="padding:10px 0">
                        <SourceCodeTag :value="item.source_code" />
                        <el-button type="primary" link v-if="item.source_code == 'PddPlatform'" style="float:right;" @click="onGetOauthUrlClick(item.shop_id)">获取授权地址</el-button>
                      </div>
                    </el-col>
                  </el-row>

                </div>
              </div>

            </template>
          </el-card>
        </el-col>
        <el-col :span="6" class="m-box-card"><div class="grid-content bg-purple"></div>
          <el-card class="m-box-card">
            <template #header>
              <i class="el-icon-message el-icon--left"></i>
              <span>公告信息</span>
              <el-button class="card-more-button" text><i class="el-icon-more el-icon--left"></i></el-button>
            </template>
            <el-empty description="暂无数据" />
          </el-card>
        </el-col>
      </el-row>
    </el-main>
  </el-container>
</template>

<script>
import OauthUrlShowView from '@/views/v1/platform/pdd/OauthUrlShowView'
import SourceCodeTag from '@/components/v1/jxgocs/tags/SourceCodeTag'
import AuthUtils from '@/utils/auth-utils.js';
import { apiGetCurrentPartnerDashboardProfile } from '@/api/merchant.js';
// import GlobalConfig from '@/config/index.js'

export default {
  name: 'index-page',
  components: {
    OauthUrlShowView,
    SourceCodeTag,
  },
  data() {
    return {
      merchantData: {
        merchant_name: '',
        oms_service_version: 0,
      },
      permissionTree: [],
    };
  },
  emits: {},
  computed: {
    nowFullYear() {
      return new Date().getFullYear()
    }
  },
  methods: {
    getMerchantProfile() {
      apiGetCurrentPartnerDashboardProfile({}).then((res) => {
        // console.log(res)
        this.merchantData = res.data.data.merchantData
        this.permissionTree = res.data.data.permissionTree
      })
    },
    // 当点击生成授权地址时触发
    onGetOauthUrlClick(shop_id) {
      this.$refs.oauthUrlShowView.showView({ editMode: "insert", shop_id: shop_id })
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getMerchantProfile()
    })
  },
}

</script>

<style scoped>
.m-box-card>.m-box-card {
  margin-right: 20px;
}
/* TODO：更多按钮 */
.card-more-button {
  float: right; 
  padding: 3px 0
}
.partner-name {
  padding: 20px 0px;
  font-size: 20px;
}
.m-merchant-item {
  /* padding: px; */
  background: #fff;
}
.m-merchant-item-title {
  padding: 15px 8px;
}
.m-merchant-name {
  font-size: 16px;
  font-weight: 800;
  color: #333;
}
.m-merchant-code {
  font-size: 12px;
  color: #ccc;
  padding-left: 20px;
}
.m-merchant-panel {
  padding: 20px;
  background: #F5F7FA;
}
.m-shop-card {
  border: 1px solid #e5e5e5;
  border-top: 2px solid #59f;
  background: #fff;
  min-height: 100px;
  padding: 10px;
  margin: 10px;
}
</style>