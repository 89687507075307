var NavConfig = {
  // ================================================================================================
  // 商品
  // ================================================================================================
  '/v1/goods/goods/list': {title: '商品列表', nav1Index: 'goods'},
  '/v1/goods/goods/add': {title: '商品添加', nav1Index: 'goods'},
  '/v1/goods/goods/upload': {title: '商品上传', nav1Index: 'goods'},
  '/v1/goods/combine-goods/list': {title: '组合商品列表', nav1Index: 'goods'},
  '/v1/goods/cainiao-goods-quantity/list': {title: '菜鸟仓货品库存', nav1Index: 'goods'},
  '/v1/goods/goods-item/list': {title: '货品列表（新）', nav1Index: 'goods'},
  '/v1/goods/goods-item/add': {title: '货品添加（新）', nav1Index: 'goods'},
  // ================================================================================================
  // 订单
  // ================================================================================================
  '/v1/trade/order/list': {title: '订单列表', nav1Index: 'trade'},
  // ================================================================================================
  // 申报
  // ================================================================================================
  '/v1/declaration/payment/list': {title: '支付申报', nav1Index: 'declaration'},
  '/v1/declaration/customs/list': {title: '订单申报', nav1Index: 'declaration'},
  '/v1/declaration/logistics/list': {title: '物流申报', nav1Index: 'declaration'},
  '/v1/declaration/clearance/list': {title: '清关申报', nav1Index: 'declaration'},
  '/v1/declaration/warehouse/list': {title: '仓储申报', nav1Index: 'declaration'},
  '/v1/declaration/logistics/waybill': {title: '运单处理', nav1Index: 'declaration'},
  '/v1/declaration/push/notice': {title: '通知推送', nav1Index: 'declaration'},
  // ================================================================================================
  // 分销
  // ================================================================================================
  '/v1/fenxiao/fenxiao-relation/list': {title: '分销合作关系', nav1Index: 'fenxiao'},
  '/v1/fenxiao/goods/market': {title: '分销货品列表', nav1Index: 'fenxiao'},
  '/v1/fenxiao/purchase/goods-item': {title: '供货关系管理', nav1Index: 'fenxiao'},  
  '/v1/fenxiao/purchase/list': {title: '分销采购表', nav1Index: 'fenxiao'},

  // ================================================================================================
  // 账单
  // ================================================================================================
  '/v1/finance/account/list': {title: '账户信息', nav1Index: 'finance'},

  // ================================================================================================
  // 系统
  // ================================================================================================
  '/v1/system/ukey/info': {title: 'UKEY信息', nav1Index: 'system'},
  '/v1/system/image/list': {title: '图片管理', nav1Index: 'system'},
}

export default NavConfig