<template>
  <el-dialog v-model="viewVisible" :append-to-body="false" :close-on-click-modal="false" width="500px" title="修改密码"
    @open="onDialogOpen">
    <el-form ref="form" :model="formModel" label-width="100px" v-loading="isLoading" status-icon>
      <el-form-item label="旧密码" prop="input3">
        <el-input type="password" v-model="formModel.input3" autocomplete="off" show-password></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="input1">
        <el-input type="password" v-model="formModel.input1" autocomplete="off" show-password></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="input2">
        <el-input type="password" v-model="formModel.input2" autocomplete="off" show-password></el-input>
      </el-form-item>
    </el-form>

    <template #footer>
      <div>
      <el-button type="primary" size="defalut" @click="onSubmit">提交</el-button>
      <el-button type="default" size="defalut" @click="viewVisible = false">取消</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { apiV1ChangePassword } from '@/api/auth.js';

export default {
  name: 'password-edit-view',
  components: {},
  props: [],
  data() {
    return {
      viewVisible: false,
      formModel: {
        input1: '',
        input2: '',
        input3: '',
      },
      // 检验规则
      formRules: {
          input1: [
            { validator: this.validatePass, trigger: 'blur' }
          ],
          input2: [
            { validator: this.validatePass2, trigger: 'blur' }
          ],
      },
      // 拷贝规则
      cloneFields: [
      ],
    }
  },
  computed: {
  },
  methods: {
    // 显示view
    showView() {
      this.viewVisible = true
    },
    
    // 当窗体打开时
    onDialogOpen() {
    },
    validatePass(rule, value, callback) {
        if (value === '') {
          callback(new Error('请输入密码'));
        } else {
          if (this.formModel.input1 !== '') {
            this.$refs.form.validateField('input1');
          }
          callback();
        }
    },
    validatePass2(rule, value, callback) {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.formModel.input2) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    },
    // 点击提交按钮
    onSubmit() {
      this.$refs['form'].validate((validResult) => {
        if (validResult === false) {
          this.$message.error('请正确填写表单数据'); return
        } 
        
        apiV1ChangePassword({
          oldPassword: this.formModel.input3,
          newPassword: this.formModel.input1,
          newPassword2: this.formModel.input2,
        }).then((res) => {
          console.log(res.data)
          if (res.data.code != 0) {
            this.$message({
              showClose: true,
              message: res.data.message || res.data.msg,
              type: 'error'
            })
          } else {
            this.$message.success("操作成功")
            this.viewVisible = false;
            this.$emit('submit')
          }
          
        })
      });
    },

    // ===================================================================================================

  },
  mounted() {
  }
};
</script>

<style scoped>
.el-form {
  /* width: 600px; */
  margin-left: auto;
  margin-right: auto;
}
</style>
